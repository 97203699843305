import React, { useEffect } from 'react'
import { useLoading } from 'components/Loading/context'
import { useNavigate } from 'react-router-dom'
import { reqRedemptionCallback } from 'apis/lp/actions'

const PaymentResult = () => {
    const navigate = useNavigate()
    const { showLoading, hideLoading } = useLoading()

    const updateQueryParameter = (urlParams) => {
        const newParams = new URLSearchParams()
        for (const [key, value] of urlParams.entries()) {
            // console.log(`${key}, ${value}`)
            if (!key.startsWith('vpc_')) {
                newParams.append(key, value)
            }
        }
        return newParams.toString()
    }

    const handlePaymentResponse = () => {
        const urlParams = new URLSearchParams(location.search)
        const responseCode = urlParams.get('vpc_TxnResponseCode')
        const responseMessage = urlParams.get('vpc_Message')
        const transactionNo = urlParams.get('vpc_TransactionNo')
        const orderInfo = urlParams.get('vpc_OrderInfo')
        const newUrlParams = updateQueryParameter(urlParams)

        console.log('responseCode', responseCode)
        console.log('responseMessage', responseMessage)
        console.log('transactionNo', transactionNo)

        if (
            responseCode?.toString() === '0' &&
            responseMessage === 'Approved'
        ) {
            console.log('Payment success')
            const data = {
                paymentReferenceNumber: transactionNo,
                referenceNumber: orderInfo,
                status: 1,
            }

            reqRedemptionCallback(data)
                .then((response) => {
                    console.log(response)
                    hideLoading()
                    navigate(`/rewards-success?${newUrlParams}`, {
                        state: {
                            referenceNumber: orderInfo,
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    hideLoading()
                    navigate(`/rewards-failed?${newUrlParams}`, {
                        state: {
                            message: error?.message,
                        },
                    })
                })
        } else {
            console.log('Payment failed')
            const data = {
                paymentReferenceNumber: transactionNo,
                referenceNumber: orderInfo,
                status: 0,
            }

            reqRedemptionCallback(data)
                .then((response) => {
                    console.log(response)
                    hideLoading()
                    navigate(`/rewards-failed?${newUrlParams}`, {
                        state: {
                            message: responseMessage,
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    hideLoading()
                    navigate(`/rewards-failed?${newUrlParams}`, {
                        state: {
                            message: error?.message,
                        },
                    })
                })
        }
        console.log(newUrlParams)
    }

    useEffect(() => {
        showLoading()
        handlePaymentResponse()
    }, [])

    return <React.Fragment></React.Fragment>
}

export default PaymentResult
