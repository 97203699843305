import styled from 'styled-components'
import ErrorIcon from 'assets/icons/ErrorIcon.png'
import { device } from 'style/devices'

const PageNoResponse = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const lang = urlParams.get('lang')
    const goBack = () => {
        history.go(-1)
    }
    return (
        <NoWapper>
            <NoIcon src={ErrorIcon} />
            <NoText>
                {lang !== 'en-US'
                    ? `資料暫時未能提供，`
                    : `Information not available.`}
            </NoText>
            <NoTwoText>
                {lang !== 'en-US' ? `請稍後再嘗試。` : `Please try again.`}
            </NoTwoText>
            <NoAvailableButton onClick={goBack}>
                {lang !== 'en-US' ? `返回主頁` : `Back to Main Page`}
            </NoAvailableButton>
        </NoWapper>
    )
}
const NoWapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`
const NoIcon = styled.img`
    width: 60rem;
    @media ${device.tablet} {
        width: 60px;
    }
`
const NoText = styled.div`
    width: 200rem;
    line-height: 21rem;
    font-size: 16rem;
    padding-top: 30rem;
    color: #000;
    text-align: center;
    @media ${device.tablet} {
        width: 200px;
        line-height: 21px;
        font-size: 16px;
        padding-top: 30px;
    }
`

const NoTwoText = styled.div`
    width: 200rem;
    line-height: 21rem;
    font-size: 16rem;
    color: #000;
    text-align: center;
    @media ${device.tablet} {
        width: 200px;
        line-height: 21px;
        font-size: 16px;
    }
`

const NoAvailableButton = styled.div`
    width: 351rem;
    height: 40rem;
    line-height: 40rem;
    background-color: #003c84;
    color: #fff;
    text-align: center;
    font-size: 16rem;
    border-radius: 40rem;
    margin-top: 32rem;
    @media ${device.tablet} {
        width: 351px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        border-radius: 40px;
        margin-top: 173px;
    }
`
export default PageNoResponse
