import React, { useEffect, useState } from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    useSearchParams,
} from 'react-router-dom'
import { routes } from 'routes'
import { store } from 'store'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { setSourceType, setCuno } from 'store/modules/urlParams'
import { setAppConfigFunction, setPageConfig } from 'store/modules/appConfigs'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { useLoading } from 'components/Loading/context'
import useDidMount from 'utils/useDidMount'
import { login, getMemberProfile } from 'apis/mvp/actions'
import { setUserProfile, setProfileLoaded } from 'store/modules/profile'
import { convertMemberProfileResults } from 'utils/common'
import bridge from 'utils/mobileJSBridge.js'
import Loading from 'components/Loading/index'
import PageUnavailable from 'components/PageUnavailable'

const client = new ApolloClient({
    uri:
        process.env.REACT_APP_CMS_API ||
        'https://www.iuww.com/content-api/LoyaltyEB/api/graph',
    cache: new InMemoryCache(),
    headers: {
        sc_apikey: '5DC5FC12-3DDF-4E70-A8D1-86A34EC13ECF',
    },
})

const urlParams = new URLSearchParams(window.location.search)
const source = urlParams.get('source')
const App = ({ appConfigs }) => {
    return (
        <ApolloProvider client={client}>
            <Provider store={store}>
                <Router>
                    <MainApp appConfigs={appConfigs} />
                </Router>
            </Provider>
        </ApolloProvider>
    )
}

const renderRoutes = (routes) => {
    return (
        <React.Fragment>
            {routes.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                ></Route>
            ))}
        </React.Fragment>
    )
}

const vConsoleShow = (lastName) => {
    if (process.env.REACT_APP_VCONSOLE === 'true') {
        window.vconsole.showSwitch()
    } else if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        if (lastName.toLowerCase().includes('lct')) {
            window.vconsole.showSwitch()
        }
    }
}

const MainApp = ({ appConfigs }) => {
    const dispatch = useDispatch()
    const { hideLoading } = useLoading()
    const [requestingToken, setRequestingToken] = useState(true)
    const [newRoutes, setNewRoutes] = useState([])
    const { i18n } = useTranslation()
    const { newRoutesWhitelisting, functionWhitelisting } = useSelector(
        (state) => state.appConfigs
    )
    const [searchParams] = useSearchParams()
    const [isInitReady, setIsInitReady] = useState(false)
    const [isInitError, setIsInitError] = useState(false)

    const getMemberProfileInformation = () => {
        const body = {
            lang: i18n.language?.toLocaleLowerCase() || 'en-us',
        }
        const headers = {
            'X-Request-JWT': localStorage.getItem('api-token'),
            'X-Request-AccEntryCode': 'Jwt',
        }
        getMemberProfile(body, headers)
            .then((response) => {
                const wcrmMemberProfile = response.data.data
                console.log('Member Profile responses', response)
                const memberProfile =
                    convertMemberProfileResults(wcrmMemberProfile)
                const lastName = memberProfile?.lastname || ''
                vConsoleShow(lastName)
                dispatch(setUserProfile(memberProfile))
                dispatch(setProfileLoaded())
                setIsInitReady(true)
                hideLoading()
            })
            .catch((error) => {
                console.log(error, 'error')
                setIsInitError(true)
                hideLoading()
            })
    }

    useDidMount(() => {
        const IsEU = urlParams.get('gdpr') || '0'
        console.log('IsEU', IsEU)

        window.adobeDataLayer?.push({
            event: 'watracking',
            IsEU: IsEU === '0',
        })
    })

    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    useEffect(() => {
        dispatch(setSourceType(source))
        console.log(
            `version：${process.env.REACT_APP_VERSION};env：${process.env.REACT_APP_ENVIRONMENT}`
        )
        if (JSON.parse(process.env.REACT_APP_GET_APP_CONFIG)) {
            const pages = appConfigs?.pages || []
            const configFunction = appConfigs?.configFunction || []
            const newRoutes = routes.filter((i) => {
                const pathName = i.path.slice(1)
                if (pathName) {
                    return (
                        pages.includes(pathName) ||
                        newRoutesWhitelisting.some((i) =>
                            i instanceof RegExp
                                ? i.test(pathName)
                                : i === pathName
                        )
                    )
                }
                return false
            })
            setNewRoutes(newRoutes)
            console.log(appConfigs)
            dispatch(setPageConfig(appConfigs.models))
            dispatch(
                setAppConfigFunction([
                    ...pages,
                    ...configFunction,
                    ...functionWhitelisting,
                ])
            )
        } else {
            setNewRoutes(routes)
            dispatch(setPageConfig(appConfigs.models))
            dispatch(setAppConfigFunction(['*']))
        }

        if (process.env.REACT_APP_PREVIEW_VERSION === 'true') {
            console.log('Start Preview Mode!')
            setRequestingToken(false)
            setIsInitReady(true)
        } else {
            console.log('Before Call App JSBridge:', new Date().toISOString())
            setTimeout(() => {
                const getCuno = async () => {
                    const cuno = await bridge.getLocalStorage('cwlCUNO')
                    console.log(
                        'Get cuno from 3-in-1 App starts:',
                        format(new Date(), 'dd/MM/yyyy hh:mm:ss')
                    )
                    dispatch(setCuno(cuno))
                    console.log('cuno', cuno)
                    console.log(
                        'Get cuno from 3-in-1 App end:',
                        format(new Date(), 'dd/MM/yyyy hh:mm:ss')
                    )
                }
                getCuno()
                if (window.LoyaltyJSBridge) {
                    console.log(`Clear Local Storage Cache.`)
                    localStorage.removeItem('api-token')
                    console.log(
                        'Get token from 3-in-1 starts:',
                        format(new Date(), 'dd/MM/yyyy hh:mm:ss')
                    )
                    window.LoyaltyJSBridge?.retreiveJWTToken()
                        .then((response) => {
                            console.log(response || 'no token receive')
                            console.log(
                                'Get token from 3-in-1 ends:',
                                format(new Date(), 'dd/MM/yyyy hh:mm:ss')
                            )
                            const body = {}
                            const headers = {
                                'X-Request-JWT': response,
                                'X-Request-AccEntryCode': 'Jwt',
                            }
                            login(body, headers)
                                .then((response) => {
                                    console.log(response?.data?.data?.token)
                                    const token = response?.data?.data?.token
                                    if (token) {
                                        localStorage.setItem('api-token', token)
                                        vConsoleShow(token)
                                    }
                                    setRequestingToken(false)
                                })
                                .catch((error) => {
                                    console.log(error)
                                    setIsInitError(true)
                                })
                        })
                        .catch((error) => {
                            console.log(
                                'LoyaltyJSBridge.retreiveJWTToken error:',
                                error
                            )
                            setIsInitError(true)
                        })
                } else {
                    console.log(
                        'LoyaltyJSBridge error: No Bridge or Browser Debug Mode.'
                    )
                    setIsInitError(true)
                    setRequestingToken(false)
                }
            }, 1000)
        }
    }, [])

    useEffect(() => {
        if (searchParams.get('pushNotification')?.toLowerCase() === 'true') {
            const currentUrl = window.location.href
            history.pushState({}, null, `/pcard-details${location.search}`)
            history.pushState({}, null, currentUrl)
        }
    }, [])

    useEffect(() => {
        if (!requestingToken) {
            console.log(
                'App init ends:',
                format(new Date(), 'dd/MM/yyyy hh:mm:ss')
            )
            getMemberProfileInformation()
        }
    }, [requestingToken])

    // display the pages once all the login information has been ready.
    if (!requestingToken && isInitReady) {
        return <Routes>{renderRoutes(newRoutes)}</Routes>
    } else if (isInitError) {
        return (
            <div style={{ height: '100vh' }}>
                <PageUnavailable />
            </div>
        )
    } else return <Loading />
}

export default App
